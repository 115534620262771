<template>
  <div>
    <!-- 顶部搜索框 -->
    <div class="usertop">
      <!-- 左侧搜索 -->
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item>
            <el-date-picker
              v-model="formInline.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            />
          </el-form-item>

          <!-- <el-form-item label="操作类型:">
            <el-select v-model="formInline.status" placeholder="请选择">
              <el-option label="在用" value="1" />
              <el-option label="停用" value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="客户端类型:">
            <el-select v-model="formInline.status" placeholder="请选择">
              <el-option label="在用" value="1" />
              <el-option label="停用" value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="患者姓名:">
            <el-input
              v-model="formInline.search_words"
              placeholder="输入搜索内容"
            />
          </el-form-item>-->
        </el-form>
      </div>
      <!-- 右侧按钮 -->
      <div>
        <el-button @click="search" class="color-main" type="primary">
          <i class="fa fa-search"></i>查询
        </el-button>
        <el-button @click="search" class="color-main" type="primary">
          <i class="fa fa-refresh"></i>刷新
        </el-button>
      </div>
    </div>

    <!-- 表格内容 -->
    <div>
      <el-table
        v-loading="loading"
        ref="multipleTableRef"
        :data="tableData"
        style="width: 100%"
        height="500"
        border
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="selection" width="55" /> -->
        <el-table-column property="created_time" label="时间" />
        <el-table-column property="model_name" label="操作模块" />
        <el-table-column property="function_name" label="操作类型" />
        <el-table-column property="role.desc" label="操作描述" />
        <el-table-column width="180" label="请求参数">
          <template #default="scope">
            <el-popover
              placement="bottom"
              :width="400"
              trigger="hover"
              :content="scope.row.request_data"
            >
              <template #reference>
                <div class="tabctext">{{ scope.row.request_data }}</div>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column width="150" property="response_data" label="返回参数">
          <template #default="scope">
            <el-popover
              placement="bottom"
              :width="700"
              :hide-after="0"
              trigger="hover"
              :content="scope.row.response_data"
            >
              <template #reference>
                <div class="tabctext">{{ scope.row.response_data }}</div>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column property="route" label="请求URL" />
        <el-table-column property="ip" label="请求IP" />
        <el-table-column property="username" label="操作人" />
        <!-- <el-table-column property="username" label="客户端类型" /> --> </el-table
      >、 、
      <div class="pagination-box">
        <el-pagination
          v-model:currentPage="pageData.currentPage"
          v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
import service from '@/utils/request'
export default {
  setup() {
    const state = reactive({
      formInline: {
        status: '1',
      },
      loading: true,
      statuslist: [
        { name: '在用', id: 1 },
        { name: '停用', id: 2 },
      ],
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      tableData: [],
    })
    onMounted(() => {
      tobody()
    })
    // 获取列表
    const tobody = async () => {
      state.loading = true
      let data = {
        size: state.pageData.pageSize,
        current: state.pageData.currentPage,
      }
      let res = await service.post('/api/operate/list', data)
      if (res.code === 0) {
        if (res.data.count > 0) {
          state.tableData = res.data.record
          state.pageData.totalSum = res.data.count
        } else {
          state.tableData = []
        }
      }
      state.loading = false
    }
    // 条数改变时
    const handleSizeChange = (e) => {
      state.pageData.pageSize = e
      tobody()
    }
    // 页数改变时
    const handleCurrentChange = (e) => {
      state.pageData.currentPage = e
      tobody()
    }
    // 查询
    const search = () => {
      tobody()
    }

    return {
      ...toRefs(state),
      tobody,
      handleSizeChange,
      handleCurrentChange,
      search,
    }
  },
}
</script>

<style scoped lang="scss">
.displya_flex {
  display: flex;
  justify-content: space-between;
}
.usertop {
  margin-bottom: 8px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  &:deep(.el-alert--warning.is-light) {
    background-color: rgba(49, 102, 174, 0.1);
    color: #3166ae;
  }
}
.tabctext {
  height: 20px;
  overflow: hidden;
  cursor: pointer;
  color: rgba(49, 102, 174, 1);
}
</style>
