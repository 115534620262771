<template>
  <div class="adviceBox">
    <!-- tabs标签页 -->
    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="院区配置" name="1">
          <Supports ref="Supports"></Supports>
        </el-tab-pane>
        <el-tab-pane label="操作日志" name="2">
          <journal ref="journal"></journal
        ></el-tab-pane>
        <el-tab-pane label="医疗文本模板" name="3">
          <Meidcal ref="Meidcal"></Meidcal
        ></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, ref } from 'vue'
import Supports from './Supports.vue'
import journal from './journal.vue'
import Meidcal from './Medicaldocuments.vue'
export default {
  components: {
    Supports,
    journal,
    Meidcal,
  },
  setup() {
    const state = reactive({
      activeName: '1',
    })
    onMounted(() => {})
    // 调用院区配置方法
    const Supports = ref()
    // 调用操作日志方法
    const journal = ref()
    // 调用医疗文本方法
    const Meidcal = ref()
    // tabs切换
    const handleClick = () => {
      if (state.activeName == '1') {
        Supports.value.tobody()
      } else if (state.activeName == '2') {
        journal.value.tobody()
      } else {
        Meidcal.value.tobody()
      }
    }

    return {
      ...toRefs(state),
      handleClick,
      Supports,
      journal,
      Meidcal,
    }
  },
}
</script>

<style scoped lang="scss">
.adviceBox {
  height: 728px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 10px;
}
</style>
