<template>
  <div>
    <!-- tabs标签页 -->
    <div>
      <div class="displya_flex">
        <!-- 左侧 -->
        <div style="width: 100%">
          <div class="displya_flex" style="margin-bottom: 10px">
            <!-- 头部 -->
            <div class="tabletext">院区</div>
            <div>
              <el-button
                class="color-main"
                type="primary"
                @click="modParient(1)"
              >
                <i class="fa fa-plus-circle"></i>新增
              </el-button>
            </div>
          </div>
          <!-- 表格 -->
          <div class="tables">
            <el-table
              v-loading="loading"
              :data="tableData"
              stripe
              border
              max-height="500px"
              style="min-width: 100%"
            >
              <el-table-column prop="id" label="排序" />
              <el-table-column prop="name" label="名称" />
              <el-table-column prop="description" label="介绍" />
              <el-table-column fixed="right" label="操作" width="220">
                <template #default="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="bottomClick(scope.row.id, 'down')"
                  >
                    <div class="icons">
                      <img src="../../../assets/img/bottom.png" alt srcset />
                    </div>
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click="bottomClick(scope.row.id, 'up')"
                  >
                    <div class="icons">
                      <img src="../../../assets/img/top.png" alt srcset />
                    </div>
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click="modParient(2, scope.row)"
                  >
                    <span class="txfonts modify">改</span>
                  </el-button>
                  <el-popconfirm
                    title="是否确定删除?"
                    @confirm="leftdel(scope.row.id)"
                  >
                    <template #reference>
                      <el-button type="text" size="small">
                        <span class="txfonts del">删</span>
                      </el-button>
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 右侧 -->

        <!-- <div class="rigthc_table">
          
          <div class="displya_flex" style="margin-bottom: 10px">
            
            <div class="tabletext">接诊区</div>
            <div>
              <el-button
                class="color-main"
                type="primary"
                @click="tabParient(1)"
              >
                <i class="fa fa-plus-circle"></i>新增
              </el-button>
            </div>
          </div>
          
          <div class="tables">
            <el-table
              :data="rightableData"
              stripe
              border
              max-height="500px"
              style="min-width: 100%"
            >
              <el-table-column prop="id" label="排序" />
              <el-table-column prop="name" label="名称" />
              <el-table-column prop="description" label="介绍" />
              <el-table-column fixed="right" label="操作" width="220">
                <template #default="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="rightClick(scope.row.id, 'down')"
                  >
                    <div class="icons">
                      <img src="../../../assets/img/bottom.png" alt srcset />
                    </div>
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click="rightClick(scope.row.id, 'down')"
                  >
                    <div class="icons">
                      <img src="../../../assets/img/top.png" alt srcset />
                    </div>
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click="tabParient(2, scope.row)"
                  >
                    <span class="txfonts modify">改</span>
                  </el-button>
                  <el-popconfirm
                    title="是否确定删除?"
                    @confirm="del(scope.row.id)"
                  >
                    <template #reference>
                      <el-button type="text" size="small">
                        <span class="txfonts del">删</span>
                      </el-button>
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div> -->

        <!-- 新增或休息院区弹窗 -->
        <div>
          <BaseDialog
            title="新增院区"
            :isshow="show"
            @handleShow="
              (val) => {
                show = val
              }
            "
            width="500px"
            height="auto"
          >
            <div>
              <el-form
                ref="ruleFormRef"
                :model="ruleForm"
                :rules="rules"
                label-width="90px"
                class="demo-ruleForm"
              >
                <el-form-item label="院区名称:" prop="name">
                  <el-input v-model="ruleForm.name" />
                </el-form-item>
                <el-form-item label="介绍:">
                  <el-input
                    v-model="ruleForm.description"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    type="textarea"
                  />
                </el-form-item>
              </el-form>
              <!-- 下划线 -->
              <el-divider />
              <!-- 底部按钮 -->
              <div class="displya_flex">
                <div></div>
                <el-row class="mb-4">
                  <el-button @click="show = false">取消</el-button>
                  <el-button type="primary" @click="addcomfig">确定</el-button>
                </el-row>
              </div>
            </div>
          </BaseDialog>
        </div>

        <!-- 新增或休息接诊区弹窗 -->
        <div>
          <BaseDialog
            title="新增接诊区"
            :isshow="adshow"
            @handleShow="
              (val) => {
                adshow = val
              }
            "
            width="25%"
            height="auto"
          >
            <div>
              <el-form
                ref="ruleFormRef"
                :model="form"
                :rules="rules"
                label-width="110px"
                class="demo-ruleForm"
              >
                <el-form-item label="接诊区名称:" prop="name">
                  <el-input v-model="form.name" />
                </el-form-item>
                <el-form-item label="分组:">
                  <el-select v-model="form.type">
                    <el-option
                      v-for="item in dictList[168000000]"
                      :key="item.id"
                      :label="item.name"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="介绍:">
                  <el-input
                    v-model="form.description"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    type="textarea"
                  />
                </el-form-item>
              </el-form>
              <!-- 下划线 -->
              <el-divider />
              <!-- 底部按钮 -->
              <div class="displya_flex">
                <div></div>
                <el-row class="mb-4">
                  <el-button @click="adshow = false">取消</el-button>
                  <el-button type="primary" @click="rightcomfig"
                    >确定</el-button
                  >
                </el-row>
              </div>
            </div>
          </BaseDialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/index.vue'
import { ElMessage } from 'element-plus'
import { gitDictList } from '@/utils/tool'
import service from '@/utils/request'
export default {
  components: {
    BaseDialog,
  },
  setup() {
    const state = reactive({
      show: false,
      adshow: false,
      loading: true,
      dictList: {},
      ruleForm: {},
      form: {},
      addIndex: null,
      addrightIndex: null,
      tableData: [],
      rightableData: [],
    })
    onMounted(() => {
      tobody()
      initData()
    })

    // 左侧列表点击
    const rowclick = (row) => {
      state.sid = row.id
      rightbody(row.id)
    }
    // 获取字典
    const initData = async () => {
      state.dictList = await gitDictList(['168000000'])
    }

    // 获取左侧列表
    const tobody = async () => {
      state.loading = true
      let res = await service.post('/api/hospital/hospitalList', { pid: 0 })
      if (res.code === 0) {
        if (res.data.length != 0) {
          state.tableData = res.data
          state.loading = false
          state.sid = res.data[0].id
          // rightbody(res.data[0].id)
        } else {
          state.tableData = []
        }
      }
      state.loading = false
    }
    // 右侧表格列表
    const rightbody = async (id) => {
      let res = await service.post('/api/hospital/hospitalList', { pid: id })
      if (res.code === 0) {
        if (res.data.total != 0) {
          state.rightableData = res.data
        } else {
          state.rightableData = []
        }
      }
    }
    // 右侧删除
    const del = async (id) => {
      let res = await service.post('/api/hospital/delete', { id: id })
      if (res.code === 0) {
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        rightbody(state.sid)
      }
    }
    // 新增院区
    const addcomfig = async () => {
      if (!state.ruleForm.id) {
        state.ruleForm.parent_id = 0
        state.ruleForm.type = ''
        let res = await service.post('/api/hospital/saveCreat', state.ruleForm)
        if (res.code === 0) {
          ElMessage({
            message: '添加成功',
            type: 'success',
          })
          state.show = false
          tobody()
        }
      } else {
        state.ruleForm.type = ''
        let res = await service.post('/api/hospital/saveEdit', state.ruleForm)
        if (res.code === 0) {
          ElMessage({
            message: '编辑成功',
            type: 'success',
          })
          state.show = false
          tobody()
        }
      }
    }
    // 排序操作
    const bottomClick = async (id, row) => {
      let data = {
        id: id,
        type: row,
      }
      let res = await service.post('/api/hospital/sortHospitalArea', data)
      if (res.code === 0) {
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        tobody()
        rightbody(state.sid)
      }
    }
    const rightClick = async (id, row) => {
      let data = {
        id: id,
        type: row,
      }
      let res = await service.post('/api/hospital/sortHospitalArea', data)
      if (res.code === 0) {
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        rightbody(state.sid)
      }
    }
    // 接诊区新增
    const rightcomfig = async () => {
      if (!state.form.id) {
        state.form.parent_id = state.sid
        let res = await service.post('/api/hospital/create', state.form)
        if (res.code === 0) {
          ElMessage({
            message: '添加成功',
            type: 'success',
          })
          state.adshow = false
          rightbody(state.sid)
        }
      } else {
        let res = await service.post('/api/hospital/saveEdit', state.form)
        if (res.code === 0) {
          ElMessage({
            message: '修改成功',
            type: 'success',
          })
          state.adshow = false
          rightbody(state.sid)
        }
      }
    }
    // 左侧侧表格删除
    const leftdel = async (id) => {
      let res = await service.post('/api/hospital/delete', { id: id })
      if (res.code === 0) {
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        tobody()
      }
    }
    // 表单校验规则
    const rules = reactive({
      name: [
        {
          required: true,
          message: '此项必填,请输入',
        },
      ],
      type: [
        {
          required: true,
          message: '此项必填,请输入',
        },
      ],
    })
    // 新增或修改院区
    const modParient = (index, row) => {
      state.ruleForm = {}
      if (index == 2) {
        state.ruleForm = JSON.parse(JSON.stringify(row))
      }
      state.addIndex = index
      state.show = true
    }
    // 新增或修改接诊区
    const tabParient = (index, row) => {
      state.form = {}
      if (index == 2) {
        state.form = JSON.parse(JSON.stringify(row))
      }
      state.addrightIndex = index
      state.adshow = true
    }
    return {
      ...toRefs(state),
      modParient,
      tabParient,
      rules,
      tobody,
      leftdel,
      rowclick,
      rightbody,
      confirm,
      del,
      addcomfig,
      initData,
      rightcomfig,
      bottomClick,
      rightClick,
    }
  },
}
</script>

<style scoped lang="scss">
.displya_flex {
  display: flex;
  justify-content: space-between;
  .searchcss {
    cursor: pointer;
  }
}
.icons {
  width: 22px;
  height: 22px;
}
.rigthc_table {
  margin-left: 20px;
  width: calc(100% - 40%);
  &:deep(.el-alert--warning.is-light) {
    background-color: rgba(49, 102, 174, 0.1);
    color: #3166ae;
  }
  .rigth_top {
    height: 28px;
    line-height: 28px;
    margin-bottom: 15px;
  }
}
.arrow-right {
  width: 21px;
  height: 21px;
  background: rgba(112, 210, 64, 0.2);
  opacity: 1;
  color: #3fbd00;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
}
.demo-ruleForm {
  margin-top: 20px;
}
.tabletext {
  line-height: 28px;
  color: #000;
  font-weight: 500;
  font-family: 'Source Han Sans CN-Medium', 'Source Han Sans CN';
}
.modify {
  background-color: #3166ae;
}
.del {
  background-color: #ffecec;
  color: #ff6b6b;
  font-weight: 700;
}
</style>
